import React, { useState, useRef, useEffect } from 'react';
import { BsBellFill } from 'react-icons/bs';
import { formatRelativeTime } from "../utils";
import { Link } from 'react-router-dom';

const NotificationIcon = ({ notifications }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [unreadCount, setUnreadCount] = useState(
        Array.isArray(notifications) ? notifications.filter((notification) => notification.read === 1).length : 0
    );


    const openModal = () => {
        setShowModal(true);
        setShowDropdown(false);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const dropdownRef = useRef(null); // Create a ref for the dropdown
    const modalRef = useRef(null);


    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    const topNotifs = notifications && Array.isArray(notifications)
        ? notifications.slice(0, 10)
        : [];

    const unreadNotifs = notifications && notifications.filter((notifications) => notifications.read && notifications.read === 1).length

    // Close dropdown if clicked outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }

            if (modalRef.current && !modalRef.current.contains(event.target)) {
                closeModal();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef, modalRef]);

    const NotificationList = ({ notifs, isModal }) => (
        <ul style={{
            listStyle: 'none',
            padding: 0,
            margin: 0,
            maxHeight: isModal ? '400px' : 'auto',
            overflowY: isModal ? 'auto' : 'visible'
        }}>
            {notifs.slice().reverse().map((notification, index) => (
                <Link key={index} target='_blank' to={'/dashboard/notifications/view/' + notification.id + '?url=' + notification.url}>
                    <li style={{
                        padding: '10px',
                        borderBottom: '1px solid #ccc',
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: notification.read ? '#f0f8ff' : '#fff'
                    }}>
                        <div style={{ flex: 1 }}>{notification.body}</div>
                        <div style={{ marginLeft: '10px', fontSize: '0.8em', color: '#666' }}>
                            {formatRelativeTime(notification.date || Date.now())}
                        </div>
                    </li>
                </Link>
            ))}
        </ul>
    );

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <BsBellFill
                size={30}
                color="#044f66"
                style={{ paddingRight: 10 }}
                onClick={toggleDropdown}
            />
            {unreadNotifs > 0 && (
                <div
                    style={{
                        position: 'absolute',
                        top: -5,
                        right: -2,
                        backgroundColor: 'red',
                        borderRadius: '50%',
                        width: 20,
                        height: 20,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 12,
                        color: 'white',
                    }}
                    onClick={toggleDropdown}
                >
                    {unreadNotifs}
                </div>
            )}
            {showDropdown && (
                <div
                    ref={dropdownRef} // Attach the ref to the dropdown
                    style={{
                        position: 'absolute',
                        top: '100%',
                        right: 0,
                        width: '300px', // Adjust the width as needed
                        backgroundColor: 'white',
                        border: '1px solid #ccc',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        zIndex: 1,
                        borderRadius: '4px',
                        overflow: 'hidden',
                    }}
                >
                    <div style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                        <strong>Notifications</strong>
                    </div>
                    <ul style={{ listStyle: 'none', padding: 0, marginTop: "-1px", marginBottom: "1px" }}>
                        {topNotifs !== null && topNotifs.slice().reverse().map((notification, index) => (
                            <Link target='_blank' to={'/dashboard/notifications/view/' + notification.id + '?url=' + notification.url}>
                                <li
                                    key={index}
                                    style={{
                                        padding: '10px',
                                        borderBottom: '1px solid #ccc',
                                        display: 'flex',
                                        alignItems: 'center',
                                        backgroundColor: notification.read ? '#f0f8ff' : '#fff'

                                    }}
                                >
                                    <div style={{ flex: 1 }}>{notification.body}</div>
                                    <div style={{ marginLeft: '10px' }}>
                                        {formatRelativeTime(notification.date || Date.now())}
                                    </div>
                                </li>
                            </Link>

                        ))}
                    </ul>
                    <div
                        style={{
                            padding: '10px',
                            textAlign: 'center',
                            color: '#1877f2',
                            cursor: 'pointer',
                        }}
                        onClick={openModal}
                    >
                        See All
                    </div>
                </div>
            )}
            {showModal && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.75)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 1000,
                }}>
                    <div ref={modalRef} style={{
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '8px',
                        width: '80%',
                        maxWidth: '600px',
                        maxHeight: '80%',
                        overflow: 'auto',
                    }}>
                        <h2>All Notifications</h2>
                        <NotificationList notifs={notifications} isModal={true} />
                        <button onClick={closeModal} style={{
                            marginTop: '20px',
                            padding: '10px 20px',
                            backgroundColor: '#1877f2',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer'
                        }}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NotificationIcon;

import React from "react";
import { Header, Table, Label, Message } from "semantic-ui-react";
import { convertTime } from "../utils";
const getLabelColor = (day) => {
    const colorMap = {
        MON: "orange",
        TUE: "blue",
        WED: "green",
        THU: "teal",
        FRI: "purple",
        SAT: "red",
        SUN: "pink",
    };
    return colorMap[day] || "grey"; // Default to grey for unknown days
};

const TimesheetWeekTables = ({ jsonData }) => {
 

    // generate a function to map through jsonData and only keep the entries with values in their clients part
    const filterData = jsonData.filter((row) => row.clients[0] !== '');

    if (filterData.length == 0) {
        return (
            <></>
        )
    }
    
    return (
        <Table basic="very" celled padded>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell textAlign="center">Day</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Clients</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                        Projects Executed
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Start Time</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Lunch</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">End Time</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Total Hrs</Table.HeaderCell>
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {filterData.map((row, index) => (
                    <Table.Row key={index}>
                        <Table.Cell>
                            <Header textAlign="center">
                                <Label as="a" color={getLabelColor(row.day)}>
                                    {row.day}
                                </Label>
                            </Header>
                        </Table.Cell>
                        <Table.Cell>
                            <ul className="timesheet-table">
                                {row.clients && row.clients.map((client, clientIndex) => (
                                    <li key={clientIndex}>{client}</li>
                                ))}
                            </ul>
                        </Table.Cell>
                        <Table.Cell>
                            <ul className="timesheet-table">
                                {row.projects.map((project, projectIndex) => (
                                    <li key={projectIndex}>{project}</li>
                                ))}
                            </ul>
                        </Table.Cell>
                        <Table.Cell>
                            <ul className="timesheet-table">
                                {row.startTimes.map((startTime, startTimeIndex) => (
                                    <li key={startTimeIndex}>{convertTime(startTime)}</li>
                                ))}
                            </ul>
                        </Table.Cell>
                        <Table.Cell>
                            <ul className="timesheet-table">
                                {row.lunch.map((lunch, lunchIndex) => (
                                    <li key={lunchIndex}>
                                        <Label as="a" color={lunch === "YES" ? "green" : "red"}>
                                            {lunch === "YES" ? "YES" : "NO"}
                                        </Label>
                                    </li>
                                ))}
                            </ul>
                        </Table.Cell>
                        <Table.Cell>
                            <ul className="timesheet-table">
                                {row.endTimes.map((endTime, endTimeIndex) => (
                                    <li key={endTimeIndex}>{convertTime(endTime)}</li>
                                ))}
                            </ul>
                        </Table.Cell>
                        <Table.Cell>
                            <ul className="timesheet-table">
                                {row.totalHours.map((totalHour, totalHourIndex) => (
                                    <li key={totalHourIndex}>{parseFloat(totalHour)}hrs</li>
                                ))}
                            </ul>
                        </Table.Cell>
                    </Table.Row>
                ))}
            </Table.Body>
        </Table>
    );
};

export default TimesheetWeekTables;

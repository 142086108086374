import {
  Breadcrumb,
  Button,
  Form,
  Grid,
  Checkbox,
  Divider,
} from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import { Link, useParams } from "react-router-dom";
import { Dropzone, FileMosaic } from "@files-ui/react";
import { uploadFiles } from "../apiCalls";
import sendApiRequest, { API_URL } from "../api";
import { toast } from "react-toastify";
import { formatToYYYYMMDD } from "../utils";
import AuthService from "../auth";

function EditGrpReport() {
  const user = AuthService.getCurrentUser();
  const [pressureCertfiles, setPressureCert] = useState([]);
  const [housingPhotosFiles, setHousingPhotos] = useState([]);
  const [electricianCertFiles, setElectricianCert] = useState([]);
  const [electricianPhotos, setElectricianPhotos] = useState([]);
  const [data, setData] = useState({});

  const { id } = useParams();

  const [formData, setFormData] = useState({});

  useEffect(() => {
    sendApiRequest("get", "/grp/fetch/" + id).then((r) => {
      setData(r.data);
    });
  }, [id]);

  useEffect(() => {
    // Update the formData object with the loaded data
    setFormData({
      destination: data.destination || "", // Replace "" with the default value you want
      orderNo: data.orderNo || "", // Replace "" with the default value you want
      arrivingDate: data.arrivingDate || "", // Replace "" with the default value you want
      // ... (update other form fields as needed)
      collectionDate: data.meta?.collectionDate || "",
      serialNoType: data.meta?.serialNoType || "GLC",
      astiTwoTenSerialNo: data.meta?.astiTwoTenSerialNo || "",
      astiTwoTenSerialNoTwo: data.meta?.astiTwoTenSerialNoTwo || "",
      gleSerialNo: data.meta?.gleSerialNo,
      glcSerialNo: data.meta?.glcSerialNo,
      NineKWImmersion: data.meta?.["NineKWImmersion"] || "",
      SixKWImmersion: data.meta?.["SixKWImmersion"],
      bufferSerialNo: data.meta?.bufferSerialNo,
      airSourceSerialNo: data.meta?.airSourceSerialNo || "",
      electricBoiler: data.meta?.electricBoiler,
      pressureTestCerts: data.meta?.pressureTestCerts || [],
      electricianCertFiles: data.meta?.electricianCertFiles || [],
      electricianPhotos: data.meta?.electricianPhotos || [],
      photos: data.meta?.photos || [],
      electricianCert: data.meta?.electricianCert || [],
      advecoOrderNo: data.meta?.controlQualityCheck?.advecoNo,
      checkElectricalWorksComplete: data.meta?.controlQualityCheck?.electricalInfo?.checkElectricalWorksComplete,
      checkMechanicalWorksComplete: data.meta?.controlQualityCheck?.mechanicalInfo?.checkMechanicalWorksComplete,
      domesticHotWaterSystem:
        data.meta?.controlQualityCheck?.mechanicalInfo?.pressureTestReport
          ?.domesticHotWaterSystem || "",
      ashpPrimarySystem:
        data.meta?.controlQualityCheck?.mechanicalInfo?.pressureTestReport
          ?.ashpPrimarySystem || "",
      glcTankDataPlate:
        data.meta?.controlQualityCheck?.mechanicalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled?.glcTankDataPlate?.report || "",
      glcTankDataPlateSerialNo:
        data.meta?.controlQualityCheck?.mechanicalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled.glcTankDataPlate?.serialNo || "",
      gleTankDataPlate:
        data.meta?.controlQualityCheck?.mechanicalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled.gleTankDataPlate?.report || "",
      gleTankDataPlateSerialNo:
        data.meta?.controlQualityCheck?.mechanicalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled.gleTankDataPlate?.serialNo || "",
      fs1000TankDataPlate:
        data.meta?.controlQualityCheck?.mechanicalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled.fs1000TankDataPlate?.report ||
        "",
      fsOneThousandDataPlateSerialNo:
        data.meta?.controlQualityCheck?.mechanicalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled?.fs1000TankDataPlate.serialNo ||
        "",
      mechanicalInstallCarriedBy:
        data.meta?.controlQualityCheck?.mechanicalInfo
          ?.installationCarriedOutBy || "",
      installationCarriedOutDate:
        data.meta?.controlQualityCheck?.mechanicalInfo
          ?.installationCarriedOutDate || "",
      electricalInformationInstallCarriedBy:
        data.meta?.controlQualityCheck?.electricalInfo
          ?.electricalInformationInstallCarriedBy || "",
      electricalInformationInstallDate:
        data.meta?.controlQualityCheck?.electricalInfo
          ?.electricalInformationInstallDate || "",
      blackGSTUnitInsideControlPanel:
        data.meta?.controlQualityCheck?.electricalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled?.blackGstUnitInsideControlPanel
          ?.report || "",
      controlPanelModelInsideDoor:
        data.meta?.controlQualityCheck?.electricalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled?.controlPanelModelInsideDoor
          .report || "",
      controlPanelModelInsideDoorModel:
        data.meta?.controlQualityCheck?.electricalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled?.controlPanelModelInsideDoor
          .model || "",
      controlPanelModelInsideDoorSerialNo:
        data.meta?.controlQualityCheck?.electricalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled?.controlPanelModelInsideDoor
          .serialNo || "",
      controlPanelModelInsideDoorIMEI:
        data.meta?.controlQualityCheck?.electricalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled?.controlPanelModelInsideDoor
          .imei || "",
      blackGSTUnitInsideControlPanelIMEI:
        data.meta?.controlQualityCheck?.electricalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled?.blackGstUnitInsideControlPanel
          ?.imei || "",
      blackGSTUnitInsideControlPanelModel:
        data.meta?.controlQualityCheck?.electricalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled?.blackGstUnitInsideControlPanel
          ?.model || "",
      blackGSTUnitInsideControlPanelSerialNo:
        data.meta?.controlQualityCheck?.electricalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled?.blackGstUnitInsideControlPanel
          ?.serialNo || "",
      advecoContactDetailsStickerInsideDoor:
        data.meta?.controlQualityCheck?.electricalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled
          ?.advecoContactDetailsStickerInsideDoor?.report || "",
      immersionHeaterBrandingLabels:
        data.meta?.controlQualityCheck?.electricalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled?.immersionHeaterBrandingLabels
          ?.report || "",
      immersionHeaterBrandingLabelsModel:
        data.meta?.controlQualityCheck?.electricalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled?.immersionHeaterBrandingLabels
          ?.model || "",
      immersionHeaterBrandingLabelsSerialNo:
        data.meta?.controlQualityCheck?.electricalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled?.immersionHeaterBrandingLabels
          ?.serialNo || "",
      advecoBrandStickerOnFPIHeatPump:
        data.meta?.controlQualityCheck?.electricalInfo
          ?.confirmAllUnitsAreCorrectlyLabeled?.advecoBrandStickerOnFpiHeatPump
          ?.report || "",
      checkSafetyValvesArePipedtoDrain:
        data.meta?.controlQualityCheck?.qualityChecksList
          ?.checkSafetyValvesArePipedtoDrain || "",
      checkCylindersAreFixedDownCorrectly:
        data.meta?.controlQualityCheck?.qualityChecksList
          ?.checkCylindersAreFixedDownCorrectly,
      checkAllPipeworkIsClippedAndSecure:
        data.meta?.controlQualityCheck?.qualityChecksList
          ?.checkAllPipeworkIsClippedAndSecure || "",
      checkAllpipeworkIsInsulatedAndJointsTaped:
        data.meta?.controlQualityCheck?.qualityChecksList
          ?.checkAllpipeworkIsInsulatedAndJointsTaped || "",
      checkHousingForAnyDamageExternallyAndInternally:
        data.meta?.controlQualityCheck?.qualityChecksList
          ?.checkHousingForAnyDamageExternallyAndInternally || "",
      checkFrostProtectionCableWrappedAroundFlowAndReturn:
        data.meta?.controlQualityCheck?.qualityChecksList
          ?.checkFrostProtectionCableWrappedAroundFlowAndReturn || "",
      checkThatAirSourceHeatPumpIsSecureIncludingStrapsAroundTheHeatPumpAndBracketsToStabilise:
        data.meta?.controlQualityCheck?.qualityChecksList
          ?.checkThatAirSourceHeatPumpIsSecureIncludingStrapsAroundTheHeatPumpAndBracketsToStabilise ||
        "",
      checkPipeworkExitingTheUnitsIsNotTooLong:
        data.meta?.controlQualityCheck?.qualityChecksList
          ?.checkPipeworkExitingTheUnitsIsNotTooLong || "",
      qualityChecksOfElectricialInstallationCarriedBy:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksOfElectricalInstallationCarriedBy || "",
      qualityChecksOfElectricialInstallationCarriedByDate:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksOfElectricalInstallationCarriedByDate || "",
      qualityChecksOfElectricialInstallationCarriedByTwo:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksOfElectricalInstallationCarriedByTwo || "",
      qualityChecksOfElectricialInstallationCarriedByTwoDate:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksOfElectricialInstallationCarriedByTwoDate || "",
      qualityChecksOfMechnicalInstallationCarriedBy:
        data.meta?.controlQualityCheck?.qualityChecksForMechanicalInstall[0]
          .installationCarriedOutBy,
      qualityChecksOfMechnicalInstallationCarriedByDate:
        data.meta?.controlQualityCheck?.qualityChecksForMechanicalInstall[0]
          .installationCarriedOutDate || "",
      qualityChecksOfMechnicalInstallationCarriedByTwo:
        data.meta?.controlQualityCheck?.qualityChecksForMechanicalInstall[1]
          .installationCarriedOutBy || "",
      qualityChecksOfMechnicalInstallationCarriedByDateTwo:
        data.meta?.controlQualityCheck?.qualityChecksForMechanicalInstall[1]
          .installationCarriedOutDate || "",
      ensurePipeworkPressureTested:
        data.meta?.controlQualityCheck?.qualityChecksList
          ?.ensurePipeworkPressureTested,
      controlPanelSecurelyMounted:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.controlPanelSecurelyMounted || "",
      controlPanelTerminatedAndLabelled:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.controlPanelTerminatedAndLabelled || "",
      tubularHeaterFuseSpurInstalledAndConnected:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.tubularHeaterFuseSpurInstalledAndConnected || "",
      lightAndSwitchInstalledAndConnected:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.lightAndSwitchInstalledAndConnected || "",
      dutyImmersionIsolatorAndConnection:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.dutyImmersionIsolatorAndConnection || "",
      dutyStatConnectedRearOfTankAndSet:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.dutyStatConnectedRearOfTankAndSet || "",
      standbyImmersionIsolatorAndConnection:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList
          ?.dutyStatConnectedRearOfTankAndSestandbyImmersionIsolatorAndConnectiont ||
        "",
      airSourceHeatPumpIsolatorAndConnection:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.airSourceHeatPumpIsolatorAndConnection || "",
      destratPumpSpurAndConnection:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.destratPumpSpurAndConnection || "",
      kioskLight:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.kioskLight || "",
      returnPump:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.returnPump,
      rcdSocketInstalled:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.rcdSocketInstalled || "",
      tubularHeater:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.tubularHeater || "",
      earthBounding:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.earthBounding,
      nicei:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.NICEIC || "",
      ensureAllIsolators:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.ensureAllIsolators || "",
      checkUnitIsCleanInside:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.checkUnitIsCleanInside || "",
      checkNoScrewsSticking:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.checkNoScrewsSticking || "",
      gsmToPanel:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.gsmToPanel || "",
      controlPanelWiring:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.controlPanelWiring || "",
      allManualsArePresent:
        data.meta?.controlQualityCheck?.qualityCheckOfElectricalInstallation
          ?.qualityChecksList?.allManualsArePresent || "",
    });
  }, [data]);

  const updatePressureCertFiles = (incomingFiles) => {
    //do something with the files
    setPressureCert(incomingFiles);
    //even your own upload implementation
  };

  const updateElectricianCertFiles = (incomingFiles) => {
    //do something with the files
    setElectricianCert(incomingFiles);
    //even your own upload implementation
  };

  const updateElectricianPhotoFiles = (incomingFiles) => {
    //do something with the files
    setElectricianPhotos(incomingFiles);
    //even your own upload implementation
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling behavior
    });
  };

  const updateHousingPhotosFiles = (file) => {
    setHousingPhotos(file);
  };

  const removePressureCertFile = (id) => {
    updatePressureCertFiles(pressureCertfiles.filter((x) => x.id !== id));
  };

  const removeElectricianCertFile = (id) => {
    updateElectricianCertFiles(electricianCertFiles.filter((x) => x.id !== id));
  };

  const removeHousingPhotosFile = (id) => {
    updateHousingPhotosFiles(housingPhotosFiles.filter((x) => x.id !== id));
  };

  const removeElectricianPhotosFile = (id) => {
    updateElectricianPhotoFiles(electricianPhotos.filter((x) => x.id !== id));
  };

  // Handle change function
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handlesaveReport = async () => {
    const apiData = {
      destination: formData.destination,
      orderNo: formData.orderNo,
      arrivingDate: formData.arrivingDate,
      meta: {
        collectionDate: formData.collectionDate,
        serialNoType: formData.serialNoType,
        astiTwoTenSerialNo: formData.astiTwoTenSerialNo,
        astiTwoTenSerialNoTwo: formData.astiTwoTenSerialNoTwo,
        gleSerialNo: formData.gleSerialNo,
        glcSerialNo: formData.glcSerialNo,
        NineKWImmersion: formData.NineKWImmersion,
        SixKWImmersion: formData.SixKWImmersion,
        bufferSerialNo: formData.bufferSerialNo,
        airSourceSerialNo: formData.airSourceSerialNo,
        electricBoiler: formData.electricBoiler,
        electricianCert: formData.electricianCert,
        electricianPhotos: formData.electricianPhotos,
        pressureTestCerts: formData.pressureTestCerts,
        photos: formData.photos,
        controlQualityCheck: {
          advecoNo: formData.advecoOrderNo,
          mechanicalInfo: {
            checkMechanicalWorksComplete: formData.checkMechanicalWorksComplete,
            installationCarriedOutBy: formData.mechanicalInstallCarriedBy,
            installationCarriedOutDate: formData.installationCarriedOutDate,
            pressureTestReport: {
              domesticHotWaterSystem: formData.domesticHotWaterSystem,
              ashpPrimarySystem: formData.ashpPrimarySystem,
            },
            confirmAllUnitsAreCorrectlyLabeled: {
              glcTankDataPlate: {
                report: formData.glcTankDataPlate,
                serialNo: formData.glcTankDataPlateSerialNo,
              },
              gleTankDataPlate: {
                report: formData.gleTankDataPlate,
                serialNo: formData.gleTankDataPlateSerialNo,
              },
              fs1000TankDataPlate: {
                report: formData.fs1000TankDataPlate,
                serialNo: formData.fsOneThousandDataPlateSerialNo,
              },
            },
          },
          electricalInfo: {
            checkElectricalWorksComplete: formData.checkElectricalWorksComplete,
            electricalInformationInstallCarriedBy:
              formData.electricalInformationInstallCarriedBy,
            electricalInformationInstallDate:
              formData.electricalInformationInstallDate,
            confirmAllUnitsAreCorrectlyLabeled: {
              controlPanelModelInsideDoor: {
                report: formData.controlPanelModelInsideDoor,
                serialNo: formData.controlPanelModelInsideDoorSerialNo,
                imei: formData.controlPanelModelInsideDoorIMEI,
              },
              blackGstUnitInsideControlPanel: {
                report: formData.blackGSTUnitInsideControlPanel,
                imei: formData.blackGSTUnitInsideControlPanelIMEI,
                model: formData.blackGSTUnitInsideControlPanelModel,
              },
              immersionHeaterBrandingLabels: {
                report: formData.immersionHeaterBrandingLabels,
                serialNo: formData.immersionHeaterBrandingLabelsSerialNo,
                model: formData.immersionHeaterBrandingLabelsModel,
              },
              advecoContactDetailsStickerInsideDoor: {
                report: formData.advecoContactDetailsStickerInsideDoor,
              },
              advecoBrandStickerOnFpiHeatPump: {
                report: formData.advecoBrandStickerOnFPIHeatPump,
              },
            },
          },
          qualityChecksForMechanicalInstall: [
            {
              installationCarriedOutBy: formData.mechanicalInstallCarriedBy,
              installationCarriedOutDate:
                formData.installationCarriedOutDate,
            },
            {
              installationCarriedOutBy:
                formData.qualityChecksOfMechnicalInstallationCarriedByTwo,
              installationCarriedOutDate:
                formData.qualityChecksOfMechnicalInstallationCarriedByDateTwo,
            },
          ],
          qualityChecksList: {
            checkHousingForAnyDamageExternallyAndInternally:
              formData.checkHousingForAnyDamageExternallyAndInternally,
            checkAllpipeworkIsInsulatedAndJointsTaped:
              formData.checkAllpipeworkIsInsulatedAndJointsTaped,
            checkUnitIsCleanInside: formData.checkUnitIsCleanInside,
            checkFrostProtectionCableWrappedAroundFlowAndReturn:
              formData.checkFrostProtectionCableWrappedAroundFlowAndReturn,
            checkCylindersAreFixedDownCorrectly:
              formData.checkCylindersAreFixedDownCorrectly,
            checkThatAirSourceHeatPumpIsSecureIncludingStrapsAroundTheHeatPumpAndBracketsToStabilise:
              formData.checkThatAirSourceHeatPumpIsSecureIncludingStrapsAroundTheHeatPumpAndBracketsToStabilise,
            checkAllPipeworkIsClippedAndSecure:
              formData.checkAllPipeworkIsClippedAndSecure,
            ensurePipeworkPressureTested: formData.ensurePipeworkPressureTested,
            checkPipeworkExitingTheUnitsIsNotTooLong:
              formData.checkPipeworkExitingTheUnitsIsNotTooLong,
            checkSafetyValvesArePipedtoDrain:
              formData.checkSafetyValvesArePipedtoDrain,
          },
          qualityCheckOfElectricalInstallation: {
            qualityChecksOfElectricalInstallationCarriedBy:
              formData.qualityChecksOfElectricialInstallationCarriedBy,
            qualityChecksOfElectricalInstallationCarriedByDate:
              formData.qualityChecksOfElectricialInstallationCarriedByDate,
            qualityChecksOfElectricalInstallationCarriedByTwo:
              formData.qualityChecksOfElectricialInstallationCarriedByTwo,
            qualityChecksOfElectricialInstallationCarriedByTwoDate:
              formData.qualityChecksOfElectricialInstallationCarriedByTwoDate,
            qualityChecksList: {
              controlPanelSecurelyMounted: formData.controlPanelSecurelyMounted,
              controlPanelTerminatedAndLabelled:
                formData.controlPanelTerminatedAndLabelled,
              tubularHeaterFuseSpurInstalledAndConnected:
                formData.tubularHeaterFuseSpurInstalledAndConnected,
              lightAndSwitchInstalledAndConnected:
                formData.lightAndSwitchInstalledAndConnected,
              dutyImmersionIsolatorAndConnection:
                formData.dutyImmersionIsolatorAndConnection,
              dutyStatConnectedRearOfTankAndSet:
                formData.dutyStatConnectedRearOfTankAndSet,
              standbyImmersionIsolatorAndConnection:
                formData.standbyImmersionIsolatorAndConnection,
              airSourceHeatPumpIsolatorAndConnection:
                formData.airSourceHeatPumpIsolatorAndConnection,
              destrat: formData.destratPumpSpurAndConnection,
              kioskLight: formData.kioskLight,
              returnPump: formData.returnPump,
              rcdSocketInstalled: formData.rcdSocketInstalled,
              tubularHeater: formData.tubularHeater,
              earthBounding: formData.earthBounding,
              NICEIC: formData.nicei,
              ensureAllIsolators: formData.ensureAllIsolators,
              checkUnitIsCleanInside: formData.checkUnitIsCleanInside,
              checkNoScrewsSticking: formData.checkNoScrewsSticking,
              gsmToPanel: formData.gsmToPanel,
              controlPanelWiring: formData.controlPanelWiring,
              allManualsArePresent: formData.allManualsArePresent,
            },
          },
        },
      },
    };

    if (pressureCertfiles.length > 0) {
      // Upload files and set the photoUri
      const uploadData = await uploadFiles(pressureCertfiles);
      const UpPressureTestCerts = uploadData.data.map((x) => {
        return {
          name: x.file_name,
          link: x.urls.path,
        };
      });

      // Ensure formData.pressureTestCerts is an array (or initialize it as an empty array if undefined)
      formData.pressureTestCerts = formData.pressureTestCerts || [];

      // Push UpPressureTestCerts to formData.pressureTestCerts
      formData.pressureTestCerts.push(UpPressureTestCerts);
    }

    apiData.meta.pressureTestCerts = formData.pressureTestCerts;

    if (housingPhotosFiles.length > 0) {
      const uploadData = await uploadFiles(housingPhotosFiles);
      const upPhotos = uploadData.data.map((x) => {
        return {
          name: x.file_name || "",
          link: (x.urls.path || ""),
        };
      });

      // Ensure formData.photos is an array (or initialize it as an empty array if undefined)
      formData.photos = formData.photos || [];

      // Push upPhotos to formData.pressureTestCerts
      formData.photos.push(upPhotos);
    }

    apiData.meta.photos = formData.photos;

    if (electricianCertFiles.length > 0) {
      // Upload files and set the photoUri
      const uploadData = await uploadFiles(electricianCertFiles);
      const UpElectricianCert = uploadData.data.map((x) => {
        return {
          name: x.file_name,
          link: x.urls.path,
        };
      });

      // Ensure formData.photos is an array (or initialize it as an empty array if undefined)
      formData.electricianCert = formData.electricianCert || [];

      // Push upPhotos to formData.pressureTestCerts
      formData.electricianCert.push(UpElectricianCert);
    }

    apiData.meta.electricianCert = formData.electricianCert;

    if (electricianPhotos.length > 0) {
      // Upload files and set the photoUri
      const uploadData = await uploadFiles(electricianPhotos);
      const UpelectricianPhotos = uploadData.data.map((x) => {
        return {
          name: x.file_name,
          link: x.urls.path,
        };
      });

      // Ensure formData.photos is an array (or initialize it as an empty array if undefined)
      formData.electricianPhotos = formData.electricianPhotos || [];

      // Push upPhotos to formData.pressureTestCerts
      formData.electricianPhotos.push(UpelectricianPhotos);
    }

    apiData.meta.electricianPhotos = formData.electricianPhotos;

    // console.log(apiData);

    const up = await sendApiRequest("post", "/grp/edit/" + id, apiData);

    console.log(up);

    toast("GRP Record Modified");

    scrollToTop();
  };

  return (
    <AdminDashLayout>
      <>
        <div style={{ paddingTop: 40, paddingRight: 10 }}>
          <h3 className={"primary-color"}>
            GRP HOUSING, DELIVERY & COLLECTION SYSTEM
          </h3>
          <Breadcrumb>
            <Link to={"/dashboard"}>
              <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
            </Link>
            <Breadcrumb.Divider icon="right chevron" />
            <Link to={"/dashboard/grp"}>
              <Breadcrumb.Section link>GRP System</Breadcrumb.Section>
            </Link>
            <Breadcrumb.Divider icon="right chevron" />
            <Link to={"/dashboard/grp/view/" + id}>
              <Breadcrumb.Section link>{data.destination}</Breadcrumb.Section>
            </Link>
            <Breadcrumb.Divider icon="right arrow" />
            <Breadcrumb.Section>
              Edit Record: {data.destination}
            </Breadcrumb.Section>
          </Breadcrumb>
        </div>
        <div style={{ paddingTop: 30, paddingRight: 20 }}>
          <Grid>
            <Grid.Column width={10}>
              <Form>
                <Grid>
                  {(user.role == "1" || user.role == "2" || user.role == "4") && (
                    <>
                    {(user.role == "1" || user.role == "2") && (
                    <>
                      <Grid.Column width={8}>
                        <Form.Input
                          size="medium"
                          label="Destination"
                          placeholder="Enter Destination"
                          required={"true"}
                          value={formData.destination}
                          onChange={(e) =>
                            setFormData(prevState => ({ ...prevState, destination: e.target.value }))
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Form.Input
                          size="medium"
                          label="Order No"
                          placeholder="Enter Order No"
                          value={formData.orderNo}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "orderNo",
                                  value: e.target.value,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Grid.Column>
                      
                      <Grid.Column width={16}>
                        <Form.Input
                          type="date"
                          size="medium"
                          label="GRP HOUSING ARRIVING DATE"
                          placeholder="Enter GRP HOUSING ARRIVING DATE"
                          value={formatToYYYYMMDD(formData.arrivingDate)}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "arrivingDate",
                                  value: e.target.value,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <Form.Input
                          size="medium"
                          type="date"
                          label="COLLECTION DATE (LEAVING US)"
                          placeholder="Enter COLLECTION DATE (LEAVING US)"
                          value={formData.collectionDate}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "collectionDate",
                                  value: e.target.value,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Grid.Column>
                      </>
                    )}
                      <Grid.Column width={10}>
                      <Form.Field>
                        <Checkbox
                          label="ASTI 210"
                          checked={
                            formData.serialNoType === "ASTI"
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "serialNoType",
                                  value: "ASTI",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                        &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;
                        <Checkbox
                          label="GLC 200"
                          checked={
                            formData.serialNoType === "GLC"
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "serialNoType",
                                  value: "GLC",
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Form.Field>
                    </Grid.Column>
                  
                  {formData.serialNoType === 'GLC' && (
                    <>
                    <Grid.Column width={8}>
                    <Form.Input
                      size="medium"
                      label="GLE Serial No"
                      placeholder="Enter GLE Serial No"
                      value={formData.gleSerialNo}
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              name: "gleSerialNo",
                              value: e.target.value,
                            },
                          },
                          "grp"
                        )
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Form.Input
                      size="medium"
                      label="GLC Serial No"
                      placeholder="Enter GLC Serial No"
                      value={formData.glcSerialNo}
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              name: "glcSerialNo",
                              value: e.target.value,
                            },
                          },
                          "grp"
                        )
                      }
                    />
                  </Grid.Column>
                    </>
                  )}

{formData.serialNoType === 'ASTI' && (
                    <>
                    <Grid.Column width={8}>
                    <Form.Input
                      size="medium"
                      label="ASTI 210 Serial No"
                      placeholder="Enter ASTI 210 Serial No"
                      value={formData.astiTwoTenSerialNo}
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              name: "astiTwoTenSerialNo",
                              value: e.target.value,
                            },
                          },
                          "grp"
                        )
                      }
                    />
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <Form.Input
                      size="medium"
                      label="ASTI 210 Serial No"
                      placeholder="Enter ASTI 210 Serial No"
                      value={formData.astiTwoTenSerialNoTwo}
                      onChange={(e) =>
                        handleChange(
                          {
                            target: {
                              name: "astiTwoTenSerialNoTwo",
                              value: e.target.value,
                            },
                          },
                          "grp"
                        )
                      }
                    />
                  </Grid.Column>
                    </>
                  )}

{(user.role == "1" || user.role == "2") && (
                    <>
                      <Grid.Column width={8}>
                        <Form.Input
                          size="medium"
                          label="9KW IMMERSION"
                          placeholder="Enter 9KW IMMERSION"
                          value={formData.NineKWImmersion}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "NineKWImmersion",
                                  value: e.target.value,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Form.Input
                          size="medium"
                          label="6KW IMMERSION"
                          placeholder="Enter 6KW IMMERSION"
                          value={formData.SixKWImmersion}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "SixKWImmersion",
                                  value: e.target.value,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Form.Input
                          size="medium"
                          label="BUFFER SERIAL NO"
                          placeholder="Enter BUFFER SERIAL NO"
                          value={formData.bufferSerialNo}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "bufferSerialNo",
                                  value: e.target.value,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Form.Input
                          size="medium"
                          label="AIR SOURCE SERIAL NO"
                          placeholder="Enter AIR SOURCE SERIAL NO"
                          value={formData.airSourceSerialNo}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "airSourceSerialNo",
                                  value: e.target.value,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Grid.Column>
                         </>
                  )}

                      <Grid.Column width={16}>
                        <Form.Input
                          size="medium"
                          label="ELECTRIC BOILER"
                          placeholder="Enter ELECTRIC BOILER"
                          value={formData.electricBoiler}
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "electricBoiler",
                                  value: e.target.value,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={16}>
                        <label style={{ paddingBottom: 20 }}>
                          Pressure Test Certificate Upload
                        </label>
                        <Dropzone
                          onChange={updatePressureCertFiles}
                          value={pressureCertfiles}
                        >
                          {pressureCertfiles.map((file) => (
                            <FileMosaic
                              key={file.id}
                              {...file}
                              onDelete={removePressureCertFile}
                              info
                              preview
                            />
                          ))}
                        </Dropzone>
                      </Grid.Column>
                    </>
                  )}

                  {user.role == 4 && (
                    <>
                      <Grid.Column width={16}>
                        <label style={{ paddingBottom: 20 }}>
                          Electrician Test Certificate Upload
                        </label>
                        <Dropzone
                          onChange={updateElectricianCertFiles}
                          value={electricianCertFiles}
                        >
                          {electricianCertFiles.map((file) => (
                            <FileMosaic
                              key={file.id}
                              {...file}
                              onDelete={removeElectricianCertFile}
                              info
                              preview
                            />
                          ))}
                        </Dropzone>
                      </Grid.Column>

                      <Grid.Column width={16}>
                        <label style={{ paddingBottom: 20 }}>
                        Photos of Electrical Works
                        </label>
                        <Dropzone
                          onChange={updateElectricianPhotoFiles}
                          value={electricianPhotos}
                        >
                          {electricianPhotos.map((file) => (
                            <FileMosaic
                              key={file.id}
                              {...file}
                              onDelete={removeElectricianPhotosFile}
                              info
                              preview
                            />
                          ))}
                        </Dropzone>
                      </Grid.Column>
                    </>
                  )}

                  {(user.role == "1" || user.role == "2") && (
                    <>
                      <Grid.Column width={16}>
                        <label style={{ paddingBottom: 20 }}>
                          Upload Photos For This Housing
                        </label>
                        <Dropzone
                          accept={"image/*"}
                          onChange={updateHousingPhotosFiles}
                          value={housingPhotosFiles}
                        >
                          {housingPhotosFiles.map((file) => (
                            <FileMosaic
                              key={file.id}
                              {...file}
                              onDelete={removeHousingPhotosFile}
                              info
                              preview
                            />
                          ))}
                        </Dropzone>
                      </Grid.Column>
                    </>
                  )}
                </Grid>
                <br />

                {(user.role == "1" || user.role == "2" || user.role == "4") && (
                  <>

                    <div
                      className={"grp-view-page-further-checks-card"}
                      style={{ borderRadius: "10px", padding: "20px" }}
                    >

                      {(user.role == "1" || user.role == "2" || user.role == "4") && (
                    <>
                    <span
                        className={"primary-color"}
                        style={{ fontSize: 18 }}
                      >
                        GRP HOUSING CONTROL QUALITY CHECKLIST
                      </span>
                      <br />
                      <br />
                      <Grid>
                      {(user.role == "1" || user.role == "2") && (
                    <>
                        <Grid.Column width={16}>
                          <Form.Input
                            size="medium"
                            placeholder="Enter Adveco Order Number"
                            label="Enter Adveco Order Number"
                            value={formData.advecoOrderNo}
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "advecoOrderNo",
                                    value: e.target.value,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Grid.Column>
                          </>
                  )}
                      </Grid>
                      {(user.role == "1" || user.role == "2") && (
                    <>
                      <br />
                      <span
                        className={"primary-color"}
                        style={{ fontSize: 15 }}
                      >
                        <u>Mechanical Information</u>
                      </span>
                      <br />
                      <br />
                      <Grid>
                        <Grid.Column width={8}>
                          <Form.Input
                            label="Installation Carried Out By"
                            size="medium"
                            placeholder="Installation Carried Out By"
                            value={formData.mechanicalInstallCarriedBy}
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "mechanicalInstallCarriedBy",
                                    value: e.target.value,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <Form.Input
                            label="Date"
                            type="date"
                            size="medium"
                            placeholder="Installation Carried Out By"
                            value={formData.installationCarriedOutDate}
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "installationCarriedOutDate",
                                    value: e.target.value,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Grid.Column>
                      </Grid>
                         </>
                  )}
                      <br />
                      <span
                        className={"primary-color"}
                        style={{ fontSize: 15 }}
                      >
                        <u>Pressure Test Report</u>
                      </span>
                      <br />
                      <br />
                      <Grid>
                      {(user.role == "1" || user.role == "2") && (
                    <>
                        <Grid.Column width={8}>
                          <Form.Field>
                            <label>Domestic Hot Water System</label>
                            <Checkbox
                              name="domesticHotWaterSystem"
                              label="Pass"
                              value={"Pass"}
                              checked={
                                formData.domesticHotWaterSystem == "Pass"
                              }
                              onChange={() =>
                                handleChange(
                                  {
                                    target: {
                                      name: "domesticHotWaterSystem",
                                      value: "Pass",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            <Checkbox
                              name="domesticHotWaterSystem"
                              label="Fail"
                              value={"Fail"}
                              checked={
                                formData.domesticHotWaterSystem == "Fail"
                              }
                              onChange={() =>
                                handleChange(
                                  {
                                    target: {
                                      name: "domesticHotWaterSystem",
                                      value: "Fail",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        </>
                      )}
                       
                        <Grid.Column width={8}>
                          <Form.Field>
                            <label>ASHP Primary System</label>
                            <Checkbox
                              label="Pass"
                              name="ashpPrimarySystem"
                              checked={formData.ashpPrimarySystem == "Pass"}
                              onChange={() =>
                                handleChange(
                                  {
                                    target: {
                                      name: "ashpPrimarySystem",
                                      value: "Pass",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            <Checkbox
                              label="Fail"
                              name="ashpPrimarySystem"
                              checked={formData.ashpPrimarySystem == "Fail"}
                              onChange={() =>
                                handleChange(
                                  {
                                    target: {
                                      name: "ashpPrimarySystem",
                                      value: "Fail",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                      </Grid>
                      <br />
                      {(user.role == "1" || user.role == "2" || user.role == "4") && (
                    <>
                      <span
                        className={"primary-color"}
                        style={{ fontSize: 15 }}
                      >
                        <u>Confirm All Units Are Correctly Labeled</u>
                      </span>
                      <br />
                      <br />
                      <Grid>
                        <Grid.Column width={5}>
                          <Form.Field>
                            <label>GLC Tank Data Plate</label>
                            <Checkbox
                              label="Pass"
                              name="glcTankDataPlate"
                              checked={formData.glcTankDataPlate === "Pass"}
                              onChange={() =>
                                handleChange(
                                  {
                                    target: {
                                      name: "glcTankDataPlate",
                                      value: "Pass",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            <Checkbox
                              label="Fail"
                              checked={formData.glcTankDataPlate === "Fail"}
                              onChange={() =>
                                handleChange(
                                  {
                                    target: {
                                      name: "glcTankDataPlate",
                                      value: "Fail",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>

                        <Grid.Column width={5} floated={"left"}>
                          <Form.Input
                            size="small"
                            placeholder="Serial No"
                            style={{ marginTop: "10px" }}
                            value={formData.glcTankDataPlateSerialNo}
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "glcTankDataPlateSerialNo",
                                    value: e.target.value,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Grid.Column>
                      </Grid>
                      <Grid>
                        <Grid.Column width={5}>
                          <Form.Field>
                            <label>GLE Tank Data Plate</label>
                            <Checkbox
                              label="Pass"
                              name="gleTankDataPlate"
                              checked={formData.gleTankDataPlate == "Pass"}
                              onChange={() =>
                                handleChange(
                                  {
                                    target: {
                                      name: "gleTankDataPlate",
                                      value: "Pass",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            <Checkbox
                              label="Fail"
                              checked={formData.gleTankDataPlate === "Fail"}
                              onChange={() =>
                                handleChange(
                                  {
                                    target: {
                                      name: "gleTankDataPlate",
                                      value: "Fail",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>

                        <Grid.Column width={5} floated={"left"}>
                          <Form.Input
                            size="small"
                            placeholder="Serial No"
                            style={{ marginTop: "10px" }}
                            value={formData.gleTankDataPlateSerialNo}
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "gleTankDataPlateSerialNo",
                                    value: e.target.value,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Grid.Column>
                      </Grid>
                      <Grid>
                        <Grid.Column width={5}>
                          <Form.Field>
                            <label>FS1000 Tank Data Plate</label>
                            <Checkbox
                              label="Pass"
                              name="fs1000TankDataPlate"
                              checked={formData.fs1000TankDataPlate === "Pass"}
                              onChange={() =>
                                handleChange(
                                  {
                                    target: {
                                      name: "fs1000TankDataPlate",
                                      value: "Pass",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            <Checkbox
                              label="Fail"
                              checked={formData.fs1000TankDataPlate === "Fail"}
                              onChange={() =>
                                handleChange(
                                  {
                                    target: {
                                      name: "fs1000TankDataPlate",
                                      value: "Fail",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>

                        <Grid.Column width={5} floated={"left"}>
                          <Form.Input
                            size="small"
                            placeholder="Serial No"
                            style={{ marginTop: "10px" }}
                            value={formData.fsOneThousandDataPlateSerialNo}
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "fsOneThousandDataPlateSerialNo",
                                    value: e.target.value,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Grid.Column>
                      </Grid>
                      <Divider />
                      </>
                      )}
                    </>
                   )}
                      
                      <span
                        className={"primary-color"}
                        style={{ fontSize: 15 }}
                      >
                        <u>Electrical Information</u>
                      </span>
                      <br />
                      <br />
                      <Grid>
                        <Grid.Column width={8}>
                          <Form.Input
                            size="medium"
                            label="Installation Carried Out By"
                            value={
                              formData.electricalInformationInstallCarriedBy
                            }
                            placeholder="Installation Carried Out By"
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "electricalInformationInstallCarriedBy",
                                    value: e.target.value,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <Form.Input
                            size="small"
                            type="date"
                            label="Date"
                            placeholder="Date"
                            style={{ marginTop: "" }}
                            value={formData.electricalInformationInstallDate}
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "electricalInformationInstallDate",
                                    value: e.target.value,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Grid.Column>
                      </Grid>
                      <br />
                      <span
                        className={"primary-color"}
                        style={{ fontSize: 15 }}
                      >
                        <u>Confirm All Units Are Correctly Labeled</u>
                      </span>
                      <br />
                      <br />
                      <Grid>
                        <Grid.Column width={7}>
                          <Form.Field>
                            <label>
                              Control Panel Model Sticker Inside Door
                            </label>
                            <Checkbox
                              label="Pass"
                              checked={
                                formData.controlPanelModelInsideDoor === "Pass"
                              }
                              onChange={() =>
                                handleChange(
                                  {
                                    target: {
                                      name: "controlPanelModelInsideDoor",
                                      value: "Pass",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            <Checkbox
                              label="Fail"
                              checked={
                                formData.controlPanelModelInsideDoor === "Fail"
                              }
                              onChange={() =>
                                handleChange(
                                  {
                                    target: {
                                      name: "controlPanelModelInsideDoor",
                                      value: "Fail",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4} floated={"left"}>
                          <Form.Input
                            size="small"
                            placeholder="Serial No"
                            style={{ marginTop: "10px" }}
                            value={formData.controlPanelModelInsideDoorSerialNo}
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "controlPanelModelInsideDoorSerialNo",
                                    value: e.target.value,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Grid.Column>
                        <Grid.Column width={4} floated={"left"}>
                          <Form.Input
                            size="small"
                            placeholder="IMEI"
                            style={{ marginTop: "10px" }}
                            value={formData.controlPanelModelInsideDoorIMEI}
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "controlPanelModelInsideDoorIMEI",
                                    value: e.target.value,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Grid.Column>
                      </Grid>
                      <Grid>
                        <Grid.Column width={7}>
                          <Form.Field>
                            <label>Black GST Unit Inside Control Panel</label>
                            <Checkbox
                              label="Pass"
                              name="blackGSTUnitInsideControlPanel"
                              checked={
                                formData.blackGSTUnitInsideControlPanel ===
                                "Pass"
                              }
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "blackGSTUnitInsideControlPanel",
                                      value: "Pass",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            <Checkbox
                              label="Fail"
                              name="blackGSTUnitInsideControlPanel"
                              checked={
                                formData.blackGSTUnitInsideControlPanel ===
                                "Fail"
                              }
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "blackGSTUnitInsideControlPanel",
                                      value: "Fail",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4} floated={"left"}>
                          <Form.Input
                            size="small"
                            placeholder="IMEI"
                            style={{ marginTop: "10px" }}
                            value={formData.blackGSTUnitInsideControlPanelIMEI}
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "blackGSTUnitInsideControlPanelIMEI",
                                    value: e.target.value,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Grid.Column>
                        <Grid.Column width={4} floated={"left"}>
                          <Form.Input
                            size="small"
                            placeholder="Model"
                            style={{ marginTop: "10px" }}
                            value={formData.blackGSTUnitInsideControlPanelModel}
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "blackGSTUnitInsideControlPanelModel",
                                    value: e.target.value,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Grid.Column>
                      </Grid>
                      <Grid>
                        <Grid.Column width={7}>
                          <Form.Field>
                            <label>Immersion Heater Branding Labels</label>
                            <Checkbox
                              label="Pass"
                              checked={
                                formData.immersionHeaterBrandingLabels ===
                                "Pass"
                              }
                              onChange={() =>
                                handleChange(
                                  {
                                    target: {
                                      name: "immersionHeaterBrandingLabels",
                                      value: "Pass",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            <Checkbox
                              label="Fail"
                              checked={
                                formData.immersionHeaterBrandingLabels ===
                                "Fail"
                              }
                              onChange={() =>
                                handleChange(
                                  {
                                    target: {
                                      name: "immersionHeaterBrandingLabels",
                                      value: "Fail",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4} floated={"left"}>
                          <Form.Input
                            size="small"
                            placeholder="Model"
                            style={{ marginTop: "10px" }}
                            value={formData.immersionHeaterBrandingLabelsModel}
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "immersionHeaterBrandingLabelsModel",
                                    value: e.target.value,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Grid.Column>
                        <Grid.Column width={4} floated={"left"}>
                          <Form.Input
                            size="small"
                            placeholder="Serial No"
                            style={{ marginTop: "10px" }}
                            value={
                              formData.immersionHeaterBrandingLabelsSerialNo
                            }
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "immersionHeaterBrandingLabelsSerialNo",
                                    value: e.target.value,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Grid.Column>
                      </Grid>
                      <Grid>
                        <Grid.Column width={10}>
                          <Form.Field>
                            <label>
                              Adveco Contact Details Sticker Inside Door
                            </label>
                            <Checkbox
                              label="Pass"
                              checked={
                                formData.advecoContactDetailsStickerInsideDoor ===
                                "Pass"
                              }
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "advecoContactDetailsStickerInsideDoor",
                                      value: "Pass",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            <Checkbox
                              label="Fail"
                              checked={
                                formData.advecoContactDetailsStickerInsideDoor ===
                                "Fail"
                              }
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "advecoContactDetailsStickerInsideDoor",
                                      value: "Fail",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={10}>
                          <Form.Field>
                            <label>Adveco Brand Sticker On FPi Heat Pump</label>
                            <Checkbox
                              label="Pass"
                              checked={
                                formData.advecoBrandStickerOnFPIHeatPump ===
                                "Pass"
                              }
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "advecoBrandStickerOnFPIHeatPump",
                                      value: "Pass",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                            <Checkbox
                              label="Fail"
                              checked={
                                formData.advecoBrandStickerOnFPIHeatPump ===
                                "Fail"
                              }
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "advecoBrandStickerOnFPIHeatPump",
                                      value: "Fail",
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                      </Grid>
                      {(user.role == "1" || user.role == "2") && (
                    <><Divider />
                    <br />
                    <span
                      className={"primary-color"}
                      style={{ fontSize: 15 }}
                    >
                      <u>Quality Checks of Mechanical Installation</u>
                    </span>
                    <br />
                    <br />
                    <Grid>
                      <Grid.Column width={8}>
                        <Form.Input
                          size="medium"
                          placeholder="Installation Carried Out By"
                          value={
                            formData.qualityChecksOfMechnicalInstallationCarriedBy
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "qualityChecksOfMechnicalInstallationCarriedBy",
                                  value: e.target.value,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Form.Input
                          type="date"
                          size="medium"
                          placeholder="Date"
                          value={
                            formData.qualityChecksOfMechnicalInstallationCarriedByDate
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "qualityChecksOfMechnicalInstallationCarriedByDate",
                                  value: e.target.value,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Grid.Column>
                    </Grid>
                    <Grid>
                      <Grid.Column width={8}>
                        <Form.Input
                          size="medium"
                          placeholder="Installation Carried Out By"
                          value={
                            formData.qualityChecksOfMechnicalInstallationCarriedByTwo
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "qualityChecksOfMechnicalInstallationCarriedByTwo",
                                  value: e.target.value,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Grid.Column>
                      <Grid.Column width={8}>
                        <Form.Input
                          type="date"
                          size="medium"
                          placeholder="Date"
                          value={
                            formData.qualityChecksOfMechnicalInstallationCarriedByDateTwo
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "qualityChecksOfMechnicalInstallationCarriedByDateTwo",
                                  value: e.target.value,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                      </Grid.Column>
                    </Grid>
                    <br />
                    <span
                      className={"primary-color"}
                      style={{ fontSize: 15 }}
                    >
                      Quality Checks
                    </span>
                    <br />
                    <small style={{ color: "#d4d4d4" }}>
                      Please tick on completion
                    </small>
                    <br />
                    <br />
                    <Grid>
                      <Grid.Column width={15}>
                        <Form.Field>
                          <label>
                            Check Housing For Any Damage Externally and
                            Internally
                          </label>
                          <Checkbox
                            label="Done"
                            checked={
                              formData.checkHousingForAnyDamageExternallyAndInternally ===
                              true
                            }
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "checkHousingForAnyDamageExternallyAndInternally",
                                    value:
                                      !formData.checkHousingForAnyDamageExternallyAndInternally,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={15}>
                        <Form.Field>
                          <label>
                            Check Cylinders Are Fixed Down Correctly
                          </label>
                          <Checkbox
                            label="Done"
                            checked={
                              formData.checkCylindersAreFixedDownCorrectly ===
                              true
                            }
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "checkCylindersAreFixedDownCorrectly",
                                    value:
                                      !formData.checkCylindersAreFixedDownCorrectly,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={15}>
                        <Form.Field>
                          <label>
                            Check that the air source heat pump is secure,
                            including straps around the heat pump and brackets
                            to stabilise the unit during transit
                          </label>
                          <Checkbox
                            label="Done"
                            checked={
                              formData.checkThatAirSourceHeatPumpIsSecureIncludingStrapsAroundTheHeatPumpAndBracketsToStabilise ===
                              true
                            }
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "checkThatAirSourceHeatPumpIsSecureIncludingStrapsAroundTheHeatPumpAndBracketsToStabilise",
                                    value:
                                      !formData.checkThatAirSourceHeatPumpIsSecureIncludingStrapsAroundTheHeatPumpAndBracketsToStabilise,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={15}>
                        <Form.Field>
                          <label>
                            Check all pipework is clipped and secure
                          </label>
                          <Checkbox
                            label="Done"
                            checked={
                              formData.checkAllPipeworkIsClippedAndSecure ===
                              true
                            }
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "checkAllPipeworkIsClippedAndSecure",
                                    value:
                                      !formData.checkAllPipeworkIsClippedAndSecure,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={15}>
                        <Form.Field>
                          <label>
                            Ensure pipework has been pressure tested
                          </label>
                          <Checkbox
                            label="Done"
                            checked={
                              formData.ensurePipeworkPressureTested === true
                            }
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "ensurePipeworkPressureTested",
                                    value:
                                      !formData.ensurePipeworkPressureTested,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={15}>
                        <Form.Field>
                          <label>
                            Check all pipework is insulated and joints taped
                          </label>
                          <Checkbox
                            label="Done"
                            checked={
                              formData.checkAllpipeworkIsInsulatedAndJointsTaped ===
                              true
                            }
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "checkAllpipeworkIsInsulatedAndJointsTaped",
                                    value:
                                      !formData.checkAllpipeworkIsInsulatedAndJointsTaped,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={15}>
                        <Form.Field>
                          <label>
                            Check safety valves are piped to drain
                          </label>
                          <Checkbox
                            label="Done"
                            checked={
                              formData.checkSafetyValvesArePipedtoDrain ===
                              true
                            }
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "checkSafetyValvesArePipedtoDrain",
                                    value:
                                      !formData.checkSafetyValvesArePipedtoDrain,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={15}>
                        <Form.Field>
                          <label>
                            Check pipework exiting the units is not too long
                            (this may cause damage in transit)
                          </label>
                          <Checkbox
                            label="Done"
                            checked={
                              formData.checkPipeworkExitingTheUnitsIsNotTooLong ===
                              true
                            }
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "checkPipeworkExitingTheUnitsIsNotTooLong",
                                    value:
                                      !formData.checkPipeworkExitingTheUnitsIsNotTooLong,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={15}>
                        <Form.Field>
                          <label>Unit is clean and tidy inside</label>
                          <Checkbox
                            label="Done"
                            checked={formData.checkUnitIsCleanInside === true}
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "checkUnitIsCleanInside",
                                    value: !formData.checkUnitIsCleanInside,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={15}>
                        <Form.Field>
                          <label>
                            Check frost protection cable wrapped around flow
                            and return
                          </label>
                          <Checkbox
                            label="Done"
                            checked={
                              formData.checkFrostProtectionCableWrappedAroundFlowAndReturn ===
                              true
                            }
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "checkFrostProtectionCableWrappedAroundFlowAndReturn",
                                    value:
                                      !formData.checkFrostProtectionCableWrappedAroundFlowAndReturn,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column width={15}>
                      <Form.Field>
                        <label style={{ color: "#800f2f" }}>
                          Mechanical Works Complete?
                          
                        </label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.checkMechanicalWorksComplete ===
                            true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "checkMechanicalWorksComplete",
                                  value:
                                    !formData.checkMechanicalWorksComplete,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                        <br />
                        <small>By checking this field, you agree that all mechanical works and checks have been completed to a satisfactory level.</small>
                      </Form.Field>
                    </Grid.Column>
                    </Grid>
                    <Divider /></>
                      )}
                      
                      <br />
                      <span
                        className={"primary-color"}
                        style={{ fontSize: 15 }}
                      >
                        <u>Quality Checks of Electrical Installation</u>
                      </span>
                      <br />
                      <br />
                      <Grid>
                        <Grid.Column width={8}>
                          <Form.Input
                            size="medium"
                            placeholder="Installation Carried Out By"
                            label="Installation Carried Out By"
                            value={
                              formData.qualityChecksOfElectricialInstallationCarriedBy
                            }
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "qualityChecksOfElectricialInstallationCarriedBy",
                                    value: e.target.value,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <Form.Input
                            size="medium"
                            label="Date"
                            type="date"
                            placeholder="Date"
                            value={
                              formData.qualityChecksOfElectricialInstallationCarriedByDate
                            }
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "qualityChecksOfElectricialInstallationCarriedByDate",
                                    value: e.target.value,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Grid.Column>
                      </Grid>
                      <Grid>
                        <Grid.Column width={8}>
                          <Form.Input
                            size="medium"
                            placeholder="Installation Carried Out By"
                            label="Installation Carried Out By"
                            value={
                              formData.qualityChecksOfElectricialInstallationCarriedByTwo
                            }
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "qualityChecksOfElectricialInstallationCarriedByTwo",
                                    value: e.target.value,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <Form.Input
                            size="medium"
                            label="Date"
                            type="date"
                            placeholder="Date"
                            value={
                              formData.qualityChecksOfElectricialInstallationCarriedByTwoDate
                            }
                            onChange={(e) =>
                              handleChange(
                                {
                                  target: {
                                    name: "qualityChecksOfElectricialInstallationCarriedByTwoDate",
                                    value: e.target.value,
                                  },
                                },
                                "grp"
                              )
                            }
                          />
                        </Grid.Column>
                      </Grid>
                      <br />
                      <span
                        className={"primary-color"}
                        style={{ fontSize: 15 }}
                      >
                        Quality Checks
                      </span>
                      <br />
                      <small style={{ color: "#d4d4d4" }}>
                        Please tick on completion
                      </small>
                      <br />
                      <br />
                      <Grid>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>Control panel securely mounted</label>
                            <Checkbox
                              label="Done"
                              checked={
                                formData.controlPanelSecurelyMounted === true
                              }
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "controlPanelSecurelyMounted",
                                      value:
                                        !formData.controlPanelSecurelyMounted,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>Control panel terminated and labelled</label>
                            <Checkbox
                              label="Done"
                              checked={
                                formData.controlPanelTerminatedAndLabelled ===
                                true
                              }
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "controlPanelTerminatedAndLabelled",
                                      value:
                                        !formData.controlPanelTerminatedAndLabelled,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>
                              Tubular heater, fuse spur installed and connected
                            </label>
                            <Checkbox
                              label="Done"
                              checked={
                                formData.tubularHeaterFuseSpurInstalledAndConnected ===
                                true
                              }
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "tubularHeaterFuseSpurInstalledAndConnected",
                                      value:
                                        !formData.tubularHeaterFuseSpurInstalledAndConnected,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>
                              Light and switch installed and connected
                            </label>
                            <Checkbox
                              label="Done"
                              checked={
                                formData.lightAndSwitchInstalledAndConnected ===
                                true
                              }
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "lightAndSwitchInstalledAndConnected",
                                      value:
                                        !formData.lightAndSwitchInstalledAndConnected,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>
                              Duty immersion isolator and connection
                            </label>
                            <Checkbox
                              label="Done"
                              checked={
                                formData.dutyImmersionIsolatorAndConnection ===
                                true
                              }
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "dutyImmersionIsolatorAndConnection",
                                      value:
                                        !formData.dutyImmersionIsolatorAndConnection,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>
                              Duty stat connected rear of tank and set to 630C
                            </label>
                            <Checkbox
                              label="Done"
                              checked={
                                formData.dutyStatConnectedRearOfTankAndSet ===
                                true
                              }
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "dutyStatConnectedRearOfTankAndSet",
                                      value:
                                        !formData.dutyStatConnectedRearOfTankAndSet,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>
                              Standby immersion isolator and connection
                            </label>
                            <Checkbox
                              label="Done"
                              checked={
                                formData.standbyImmersionIsolatorAndConnection ===
                                true
                              }
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "standbyImmersionIsolatorAndConnection",
                                      value:
                                        !formData.standbyImmersionIsolatorAndConnection,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>
                              Air source heat pump isolator and connection
                            </label>
                            <Checkbox
                              label="Done"
                              checked={
                                formData.airSourceHeatPumpIsolatorAndConnection ===
                                true
                              }
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "airSourceHeatPumpIsolatorAndConnection",
                                      value:
                                        !formData.airSourceHeatPumpIsolatorAndConnection,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>Destrat pump spur and connection</label>
                            <Checkbox
                              label="Done"
                              checked={
                                formData.destratPumpSpurAndConnection === true
                              }
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "destratPumpSpurAndConnection",
                                      value:
                                        !formData.destratPumpSpurAndConnection,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>Kiosk light, switch and connection</label>
                            <Checkbox
                              label="Done"
                              checked={formData.kioskLight === true}
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "kioskLight",
                                      value: !formData.kioskLight,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>Return pump, fuse spur and isolator</label>
                            <Checkbox
                              label="Done"
                              checked={formData.returnPump === true}
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "returnPump",
                                      value: !formData.returnPump,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>RCD socket installed and connection</label>
                            <Checkbox
                              label="Done"
                              checked={formData.rcdSocketInstalled === true}
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "rcdSocketInstalled",
                                      value: !formData.rcdSocketInstalled,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>
                              Tubular heater, fuse spur and connection, securely
                              mounted with no damage to brackets
                            </label>
                            <Checkbox
                              label="Done"
                              checked={formData.tubularHeater === true}
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "tubularHeater",
                                      value: !formData.tubularHeater,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>
                              Earth bonding to mechanical services and
                              electrical tray
                            </label>
                            <Checkbox
                              label="Done"
                              checked={formData.earthBounding === true}
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "earthBounding",
                                      value: !formData.earthBounding,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>
                              NICEIC Installation certificate with the unit
                            </label>
                            <Checkbox
                              label="Done"
                              checked={formData.nicei === true}
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "nicei",
                                      value: !formData.nicei,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>Ensure all isolators are labelled</label>
                            <Checkbox
                              label="Done"
                              checked={formData.ensureAllIsolators === true}
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "ensureAllIsolators",
                                      value: !formData.ensureAllIsolators,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>Check unit is clean inside</label>
                            <Checkbox
                              label="Done"
                              checked={formData.checkUnitIsCleanInside === true}
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "checkUnitIsCleanInside",
                                      value: !formData.checkUnitIsCleanInside,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>
                              Check no screws sticking through the housing to
                              outside
                            </label>
                            <Checkbox
                              label="Done"
                              checked={formData.checkNoScrewsSticking === true}
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "checkNoScrewsSticking",
                                      value: !formData.checkNoScrewsSticking,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>
                              GSM to panel connections are correct (Red wire to
                              positive terminal, black wire to negative, yellow
                              wire to third terminal)
                            </label>
                            <Checkbox
                              label="Done"
                              checked={formData.gsmToPanel === true}
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "gsmToPanel",
                                      value: !formData.gsmToPanel,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>
                              Control panel wiring schematic is included inside
                              control panel door
                            </label>
                            <Checkbox
                              label="Done"
                              checked={formData.controlPanelWiring === true}
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "controlPanelWiring",
                                      value: !formData.controlPanelWiring,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                          <Form.Field>
                            <label>
                              All manuals are present (ASHP, water heater,
                              preheat, immersion heaters, control panel)
                            </label>
                            <Checkbox
                              label="Done"
                              checked={formData.allManualsArePresent === true}
                              onChange={(e) =>
                                handleChange(
                                  {
                                    target: {
                                      name: "allManualsArePresent",
                                      value: !formData.allManualsArePresent,
                                    },
                                  },
                                  "grp"
                                )
                              }
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={15}>
                      <Form.Field>
                        <label style={{ color: "#e85d04" }}>
                          Electrical Works Complete?
                          
                        </label>
                        <Checkbox
                          label="Done"
                          checked={
                            formData.checkElectricalWorksComplete ===
                            true
                          }
                          onChange={(e) =>
                            handleChange(
                              {
                                target: {
                                  name: "checkElectricalWorksComplete",
                                  value:
                                    !formData.checkElectricalWorksComplete,
                                },
                              },
                              "grp"
                            )
                          }
                        />
                        <br />
                        <small>By checking this field, you agree that all mechanical works and checks have been completed to a satisfactory level.</small>
                      </Form.Field>
                    </Grid.Column>
                      </Grid>
                    </div>
                    <br /> <br />
                  </>
                )}

                <Button
                  size="midi"
                  color="primary"
                  content="Save Changes"
                  icon="plus"
                  labelPosition="right"
                  onClick={handlesaveReport}
                />
              </Form>
            </Grid.Column>
          </Grid>
        </div>
      </>
    </AdminDashLayout>
  );
}

export default EditGrpReport;

import React from "react";
import { MdHome } from "react-icons/md";
import { Link, matchPath, useLocation } from "react-router-dom";
import pipekitsvg from '../assets/pipe.svg'
import AuthService from '../auth'

const ClientSideNav = () => {
    const user = AuthService.getCurrentUser()

    const location = useLocation();
    const isActiveRoute = (pathPattern) => {
        const match = matchPath({ path: pathPattern }, location.pathname);

        return match !== null;
    };

    return (
        <>
            <li
                className={
                    isActiveRoute("/dashboard") ||
                        isActiveRoute("/dashboard/clients") ||
                        isActiveRoute("/dashboard/clients/projects/:id") ||
                        isActiveRoute("/dashboard/projects/view/:id")
                        ? "active"
                        : ""
                }>
                <Link to={"/dashboard"}>
                    <MdHome size={30} style={{ marginBottom: "-10px" }} />
                    <span className="nav-link" style={{ marginBottom: "10px" }}>
                        Projects
                    </span>
                </Link>
            </li>
           
        </>
    );
};

export default ClientSideNav;

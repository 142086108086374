import {
    Breadcrumb,
    Button,
    Divider,
    Grid,
    Modal,
    Header,
    Icon,
    List,
    Card
} from "semantic-ui-react";
import React, { useEffect, useState } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import { FaCheckCircle, FaMapMarker } from "react-icons/fa";
import { FaCircleXmark } from "react-icons/fa6";
import Lightbox from "../components/Lightbox";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthService from "../auth";
import sendApiRequest from "../api";
import { formatToBritishFormat } from "../utils";
import { toast } from "react-toastify";
import { extractFileNameAndExtension } from "../utils";
import { updateGrpImages } from "../apiCalls";
import LightboxInner from "../components/LightboxInner";
import PipekitAssignedto from "../components/PipekitAssignedTo";
import ViewPipekitAssignedTo from "../components/ViewPipekitAssignedTo";
import ImageModal from "./ImageModal";
import ImageGallery from "react-image-gallery";

function flattenArray(dataArray) {
    if (Array.isArray(dataArray)) {
        return dataArray.flatMap((r) => {
            if (Array.isArray(r)) {
                return r.map((e) => e.link);
            } else {
                return [r.link];
            }
        });
    }
    return [];
}

function ViewPipekit() {
    const user = AuthService.getCurrentUser();
    const user_role = user.role;
    const { id } = useParams();
    const [data, setData] = useState({});
    const [meta, setMeta] = useState({});
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [serialNoType, setSerialNoType] = useState("");

    const [deleteIndex, setDeleteIndex] = useState(null);

    const [deleteModalPOpen, setDeletePModalOpen] = useState(false);

    const openDeleteModal = () => setDeletePModalOpen(true);

    const [loading, setLoading] = useState(false)

    const [editQuantity, setEditQuantity] = useState(0); // Initialize state for editQuantity
    const [isEditModalOpen, setIsEditModalOpen] = useState(false); // State for modal visibility
    const [selectedMaterialIndex, setSelectedMaterialIndex] = useState(null); // State to track selected material index

    const openEditModal = (index) => {
        setSelectedMaterialIndex(index);
        setEditQuantity(data.materials[index].QTY); // Set the current quantity in the modal
        setIsEditModalOpen(true);
    };

    const closeEditModal = () => {
        setIsEditModalOpen(false);
        setEditQuantity(0); // Reset quantity when closing
        setSelectedMaterialIndex(null); // Reset selected index
    };


  

    const handleSaveEdit = async () => {
        if (selectedMaterialIndex !== null) {
            // Update the materials array with the new quantity
            const updatedMaterials = [...data.materials];
            updatedMaterials[selectedMaterialIndex].QTY = editQuantity;

            // Update the state (if you have a state for materials)
            // setData((prevData) => ({ ...prevData, materials: updatedMaterials }));

            // Send the updated materials to the backend
            try {
                await sendApiRequest("post", "/pipekits/edit/" + data.id, { materials: JSON.stringify(updatedMaterials) });
                toast("Material quantity updated successfully");
            } catch (error) {
                console.error("Error updating materials:", error);
            }
        }

        closeEditModal(); // Close the modal after saving
    };

    const handleEmailMerchant = async () => {
        try {
            await sendApiRequest("post", "/pipekits/mail_merchant/" + data.id, {});
            toast("Merchant mailed successfully");
        } catch (error) {
            console.error("Error updating materials:", error);
        }
    };

    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await sendApiRequest("get", "/pipekits/fetch/" + id);
                setData(response.data);
            } catch (error) {
                // Handle the error gracefully
                console.error("Error fetching data:", error);
                // Optionally, you can set an error state or perform other actions
            }
        };

        fetchData(); // Call the fetchData function
    }, [id]);


    function handleDeleteModalClose() {
        setDeleteModalOpen(false);
    }

    function handleDeleteModalOpen() {
        setDeleteModalOpen(true);
    }

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const openModal = (imageSrc) => {
        setSelectedImage(imageSrc);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage('');
    };

    const generatedImages = data.pipekitPhotos ? data.pipekitPhotos.map((image) => ({
        original: image.link,
        thumbnail: image.link,
    })) : [];

    return (
        <>
            <AdminDashLayout>
                <div style={{ paddingTop: 40, paddingRight: 10 }}>
                    <Grid>
                        <Grid.Column floated="left" width={7}>
                            <h3 className={"primary-color"}>
                                VIEWING PIPEKIT {data.advecoNumber}
                            </h3>
                            <Breadcrumb>
                                <Link to={"/dashboard"}>
                                    <Breadcrumb.Section link>Dashboard</Breadcrumb.Section>
                                </Link>
                                <Breadcrumb.Divider icon="right chevron" />
                                <Link to={"/dashboard/pipekits"}>
                                    <Breadcrumb.Section link>Pipekits System</Breadcrumb.Section>
                                </Link>
                                <Breadcrumb.Divider icon="right arrow" />
                                <Breadcrumb.Section>
                                    Viewing: {data.advecoNumber}
                                </Breadcrumb.Section>
                            </Breadcrumb>
                        </Grid.Column>
                        <Grid.Column floated="right" width={6}>
                            {user_role == "1" && (
                                <>
                                    <div style={{ marginRight: 1, float: "right" }}>
                                        <div style={{ display: "flex" }}>
                                            <div>
                                                <Link to={"/dashboard/pipekits/edit/" + id}>
                                                    <Button
                                                        color="primary"
                                                        content="Edit Record"
                                                        icon="pencil"
                                                        labelPosition="right"
                                                    />
                                                </Link>

                                                <Modal
                                                    dimmer={"blurring"}
                                                    basic={true}
                                                    onClose={handleDeleteModalClose}
                                                    onOpen={handleDeleteModalOpen}
                                                    open={deleteModalOpen} // Use separate state for each modal
                                                    size="small"
                                                    trigger={
                                                        <Button
                                                            color="red"
                                                            content="Delete"
                                                            icon="trash"
                                                            labelPosition="right"
                                                        />
                                                    }
                                                >
                                                    <Header icon>
                                                        <Icon name="archive" />
                                                        Delete Record: {data.advecoNumber}?
                                                    </Header>
                                                    <Modal.Content>
                                                        <p style={{ textAlign: "center" }}>Are you sure?</p>
                                                    </Modal.Content>
                                                    <Modal.Actions>
                                                        <Button
                                                            inverted={true}
                                                            color="red"
                                                            onClick={() => handleDeleteModalClose()}
                                                        >
                                                            <Icon name="remove" /> No
                                                        </Button>
                                                        <Button
                                                            inverted={true}
                                                            color="green"
                                                            onClick={async () => {
                                                                toast("Record Successfully Deleted", {
                                                                    type: "success",
                                                                });

                                                                await sendApiRequest(
                                                                    "post",
                                                                    "/pipekits/delete/" + id
                                                                );

                                                                handleDeleteModalClose();

                                                                return navigate("/dashboard/pipekits");

                                                                // await deleteProject(project.id, localStorage.getItem('token'));
                                                            }}
                                                        >
                                                            <Icon name="checkmark" /> Yes
                                                        </Button>
                                                    </Modal.Actions>
                                                </Modal>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                            {(user_role == "2") && (
                                <>
                                    <div style={{ marginRight: 1, float: "right" }}>
                                        <div style={{ display: "flex" }}>
                                            <div>
                                                <Link to={"/dashboard/pipekits/edit/" + id}>
                                                    <Button
                                                        color="primary"
                                                        content="Edit Record"
                                                        icon="pencil"
                                                        labelPosition="right"
                                                    />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </Grid.Column>
                    </Grid>
                    <br />
                    <Card style={{ backgroundColor: '#fffbe5', paddingBottom: '0px' }}>
                        <Card.Content>
                            <ViewPipekitAssignedTo peopleAssigned={data.assignedTo} />
                        </Card.Content>
                    </Card>

                    <div style={{ fontSize: 15 }}>
                        <span className={"primary-color"} style={{ fontWeight: 'bold' }}>Adveco Order Number</span>
                        <br />
                        <span>
                            {data.advecoNumber}
                        </span>
                        <br /> <br />
                        <span className={"primary-color"} style={{ fontWeight: 'bold' }}>Pipekit Code</span>
                        <br />
                        <span>{data.pipekitCode}</span>
                        <br /> <br />

                        <span className={"primary-color"} style={{ fontWeight: 'bold' }}>LH or RH</span>
                        <br />
                        <span>{data.pipekitPosition}</span>
                        <br /> <br />
                        <span className={"primary-color"} style={{ fontWeight: 'bold' }}>Pipekit Reference / Description</span>
                        <br />
                        <span>{data.pipekitRefDesc}</span>
                        <br /> <br />
                        <span className={"primary-color"} style={{ fontWeight: 'bold' }}>Pipekit Quantity</span>
                        <br />
                        <span>{data.pipekitQty}</span>
                        <br /> <br />
                        <span className={"primary-color"} style={{ fontWeight: 'bold' }}>Date Required</span>
                        <br />
                        <span>{formatToBritishFormat(data.dateRequired)}</span>
                        <br /> <br />
                        <span className={"primary-color"} style={{ fontWeight: 'bold' }}>Pipekit Photos</span>
                        <br /><br />
                        <div>
                            {data.pipekitPhotos && data.pipekitPhotos.length > 0 ? (
                                <Grid>
                                    {data.pipekitPhotos.map((photo, index) => (
                                        <Grid.Column key={index} width={2} style={{ marginBottom: '10px' }}>
                                            <div style={{ textAlign: 'left' }}>
                                                <u>Pipekit {index + 1}</u>
                                                <br />
                                                <img
                                                    src={photo.link}
                                                    alt={`Pipekit Photo ${index + 1}`}
                                                    style={{ maxWidth: '100%', height: '200px', borderRadius: '5px', cursor: 'pointer' }}
                                                    onClick={() => openModal(index)}
                                                />
                                            </div>
                                        </Grid.Column>
                                    ))}
                                </Grid>
                            ) : (
                                <span>No photos available</span>
                            )}
                        </div>
                        <br /> <br />

                        <span className={"primary-color"} style={{ fontWeight: 'bold' }}>MATERIALS</span>
                        <br /><br />
                        <div>
                            {data.materials && data.materials.length > 0 ? (
                                <table style={{ width: '50%', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Code</th>

                                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Description</th>

                                            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Quantity</th>

                                            {user_role == "1" && (
                                                <th style={{ border: '1px solid #ddd', padding: '8px' }}>Actions</th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.materials.map((material, index) => (
                                            <tr key={index}>
                                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{material.CODE}</td>
                                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{material.DESCRIPTION}</td>
                                                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{material.QTY}</td>
                                                {user_role == "1" && (
                                                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                                                        <Icon
                                                            name="pencil"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => openEditModal(index)}
                                                        />
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            ) : (
                                <span>No materials available</span>
                            )}
                        </div>

                        <Modal open={isEditModalOpen} onClose={closeEditModal}>
                            {selectedMaterialIndex !== null && (
                                <Modal.Header>
                                    Edit Quantity: {data.materials[selectedMaterialIndex].CODE} / {data.materials[selectedMaterialIndex].DESCRIPTION}
                                </Modal.Header>
                            )}
                            <Modal.Content>
                                <div>
                                    <label>Enter New Quantity:</label>
                                    <br />
                                    <input
                                        type="number"
                                        value={editQuantity}
                                        onChange={(e) => setEditQuantity(e.target.value)}
                                        min="0" // Optional: Prevent negative values
                                        style={{ border: '1px solid #000' }}
                                    />
                                </div>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button color="red" onClick={closeEditModal}>
                                    Cancel
                                </Button>
                                <Button color="green" onClick={handleSaveEdit}>
                                    Save
                                </Button>
                            </Modal.Actions>
                        </Modal>


                        {user_role == "1" && (
                            <>
                                <br />
                                <Button
                                    size="midi"
                                    color="#18823d"
                                    style={{ backgroundColor: "#044f66", color: "#fff" }}
                                    content={loading ? "Saving..." : "Send Email to Merchant"} // Change button text based on loading state
                                    icon="mail"
                                    labelPosition="right"
                                    onClick={handleEmailMerchant}
                                    loading={loading} // Add loading prop to button
                                    disabled={loading} // Disable button while loading
                                />
                            </>
                        )}
                        <br /><br />
                        {data.worker && (
                            <>
                                <div style={{ backgroundColor: "lightgreen", padding: "10px", borderRadius: "5px", display: "flex", alignItems: "center", fontSize: "18px" }}>
                                    <span style={{ marginRight: "10px" }}><FaCheckCircle color="green" size={18} /></span>
                                    <span>Pipekit installation has been confirmed by the installer: {data.worker?.firstname}</span>
                                </div>
                            </>
                        )}

                        <br /><br />
                    </div>
                </div>

            </AdminDashLayout>

            <Modal
                open={isModalOpen}
                onClose={closeModal}
                closeIcon
                size="small"
                basic
            >
                <Modal.Content >
                    <br /> <br />
                    <ImageGallery items={generatedImages} startIndex={selectedImage} />
                </Modal.Content>
                <Modal.Actions>
                    <Button color="red" onClick={closeModal}>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
}

export default ViewPipekit;

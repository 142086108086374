import React from "react";
import { Card, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { UserAvatar } from "./UserAvatar";
import Avatar from "react-avatar";

const cardColors = ["#ede7f6", "#e8f5e9", "#e3f2fd", "#fbe9e7"];

const TimesheetCardsAdminStaffs = ({ timesheetData, month, year }) => {
  if (!timesheetData || timesheetData.length === 0) {
    return <div>No timesheet data available</div>;
  }

  return (
    <Card.Group stackable itemsPerRow={3}>
      {timesheetData.map((item, index) => (
        <Card
          key={index}
          style={{
            backgroundColor:
              cardColors[Math.floor(Math.random() * cardColors.length)],
            padding: 25,
            marginBottom: 20,
            height: 200,
          }}
          raised
        >
          <Link
            to={`/dashboard/timesheet/staff/view/${item.id}/${month}/${year}`}
            style={{
              textDecoration: "none",
              textAlign: "justify",
              color: "inherit",
            }}
          >
            <Card.Content>
              <Card.Description>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "30px",
                  }}
                >
                  {item.avatar ? (
                    <img
                      src={item.avatar}
                      alt="avatar"
                      style={{
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        marginRight: "10px",
                        marginTop: "10px",
                      }}
                    />
                  ) : (
                    <Avatar name={item?.firstname} round={true} size={"50"} />
                  )}
                  &nbsp;&nbsp; <h2>{item.fullname}</h2>
                </div>
              </Card.Description>
            </Card.Content>
          </Link>
        </Card>
      ))}
    </Card.Group>
  );
};

export default TimesheetCardsAdminStaffs;

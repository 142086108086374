import React, { useState, useEffect } from "react";
import AdminDashLayout from "../components/AdminDashLayout";
import { Button, Grid, Modal, Input, Form } from "semantic-ui-react";
import TimesheetCards from "../components/TimesheetCards";
import TimesheetsCardsAdminStaffs from "../components/TimesheetsCardsAdminStaffs";
import AuthService from "../auth";
import sendApiRequest from "../api";
import {Link} from "react-router-dom"; // Import your API request function

function Timesheet() {
  const user = AuthService.getCurrentUser();
  const user_role = user.role;

  const currentDate = new Date();
  // const currentMonth = currentDate.toLocaleString("default", { month: "long" });
  // const currentYear = currentDate.getFullYear();

  const [timesheetData, setTimesheetData] = useState([]);
  const [timesheetStaffData, setTimesheetStaffData] = useState([])
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [timesheetId, setTimesheetId] = useState('')
  const [currentMonth, setCurrentMonth] = useState(currentDate.toLocaleString("default", { month: "long" }))
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear())

  useEffect(() => {
    fetchTimesheetData(); // Fetch timesheet data when the component mounts
  }, []);

  const fetchTimesheetData = async () => {
    setLoading(true);
    try {
      // Modify the API endpoint and request method as needed
      const response = await sendApiRequest("get", "/timesheet");
      console.log(response)
      setTimesheetData(response.data.weeksCount);
      setTimesheetId(response.data.id)
    } catch (error) {
      console.error("Error fetching timesheet data:", error);
    }

    try {
      // Modify the API endpoint and request method as needed
      const response = await sendApiRequest("get", "/timesheet/staffs");
      setTimesheetStaffData(response.data);
    } catch (error) {
      console.error("Error fetching timesheet data:", error);
    }

    setLoading(false);
  };

  const handleFilterDates = async () => {
    if (!startDate) {
      return;
    }
  
    setLoading(true);
    try {
      const data = {
        startDate: startDate
      }
  
      const dateString = startDate;
      const dateParts = dateString.split("-");
  
      const month = dateParts[1];
      const year = dateParts[0];
  
      const monthNumber = month;
      const locale = "en-US";
      const formatter = new Intl.DateTimeFormat(locale, {month: "long"});
      const monthName = formatter.format(new Date(monthNumber));
  
      const response = await sendApiRequest("get", "/timesheet/staffs?month=" + monthName + "&year=" + year, data);

      setCurrentMonth(monthName)
      setCurrentYear(year)
  
      if (response.message === "success") {
        if (response.data === null) {
          //
        } else {
          setTimesheetStaffData('')
          setTimesheetStaffData(response.data);
        }
      } else {
        console.error("Error filtering dates:", response.message);
      }
    } catch (error) {
      console.error("Error filtering dates:", error);
    }
    setLoading(false);
  };

  return (
      <>
        <AdminDashLayout>
          <div style={{ paddingTop: 30, paddingRight: 20 }}>
            <div className="timesheet-container">
              <Grid>
                <Grid.Column mobile={16} computer={7} floated="left">
                  <h1>
                    <span className="timesheet-subtitle"><Link to={'/dashboard/timesheet'}>Timesheet</Link></span> /{" "}
                    {currentMonth} {currentYear}
                  </h1>
                </Grid.Column>

                <Grid.Column mobile={16} computer={3} floated="">
                  {user_role === 1 || user_role === 2 && (
                      <div className="filter-dates">
                        <Modal
                            trigger={
                              <Button
                                  content="Filter Dates"
                                  icon="calendar"
                                  labelPosition="right"
                                  color="primary"
                                  disabled={loading}
                              />
                            }
                            onClose={() => setModalOpen(false)}
                            onOpen={() => setModalOpen(true)}
                            open={modalOpen}
                            size="tiny"
                        >
                          <Modal.Header>Filter Dates</Modal.Header>
                          <Modal.Content>
                            <Form>
                              <Form.Field>
                                <label>Month & Year</label>
                                <Input
                                    type="month"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    disabled={loading}
                                />
                              </Form.Field>
                            </Form>
                          </Modal.Content>
                          <Modal.Actions>
                            <Button
                                color="youtube"
                                onClick={() => {
                                  setModalOpen(false)
                                }}
                            >
                              Close
                            </Button>
                            <Button
                                color="primary"
                                onClick={() => {
                                  handleFilterDates();
                                  setModalOpen(false);
                                }}
                                disabled={loading}
                            >
                              Apply
                            </Button>
                          </Modal.Actions>
                        </Modal>
                      </div>
                  )}
                </Grid.Column>
              </Grid>
            </div>
            <br />

            {user_role === 1 && (
                <TimesheetsCardsAdminStaffs id={timesheetId} timesheetData={timesheetStaffData} month={currentMonth} year={currentYear} />
            )}

            {user_role === 2 && (
                <TimesheetCards id={timesheetId} timesheetData={timesheetData} />
            )}
          </div>
        </AdminDashLayout>
      </>
  );
}

export default Timesheet;

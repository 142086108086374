import React from "react";
import { Card, Grid } from "semantic-ui-react";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";

const cardColors = ["#ede7f6", "#e8f5e9", "#e3f2fd", "#fbe9e7"];

const TimesheetCardsAdmin = ({ timesheetData, id, statusData = {} }) => {
    if (!timesheetData || timesheetData.length === 0) {
        return <div>No timesheet data available</div>;
    }

    const getStatusValue = (week) => {
        const weekData = statusData.weeks.find(
            (weekData) => weekData.weekCount === parseInt(week)
        );
        return weekData.status;
    };

    return (
        <Card.Group stackable itemsPerRow={3}>
            {timesheetData.map((item, index) => (
                <Card
                    key={index}
                    style={{
                        backgroundColor:
                            cardColors[Math.floor(Math.random() * cardColors.length)],
                        padding: 25,
                        marginBottom: 20,
                        height: 200,
                    }}
                    raised
                >
                    <Link
                        to={`/dashboard/timesheet/view/${id}?week=${item.week}`}
                        style={{
                            textDecoration: "none",
                            textAlign: "justify",
                            color: "inherit",
                        }}
                    >
                        <Card.Content>
                            <Grid>
                                <Grid.Column floated="left" width={10}>
                                    <Card.Header>
                                        <h2>Week {item.week}</h2>
                                    </Card.Header>
                                </Grid.Column>
                            </Grid>
                            <>
                                <Card.Description>
                                    <div
                                        style={{
                                            paddingTop: 20,
                                            paddingBottom: 0,
                                            display: "flex",
                                        }}
                                    >
                                        <h3 style={{ fontWeight: "light" }}>Signed By: </h3>{" "}
                                        &nbsp;
                                        {item.signedBy.name !== "" && (
                                            <>
                                                <Avatar
                                                    name={item.signedBy.name}
                                                    size="25"
                                                    round={true}
                                                    style={{ marginTop: 1 }}
                                                />
                                                &nbsp;&nbsp;
                                                <span style={{ fontSize: 16, marginTop: 3 }}>
                                                    {item.signedBy.name}
                                                 </span>
                                            </>
                                        )}

                                        {item.signedBy.name == "" && (
                                            <>
                                                &nbsp;
                                                <span style={{ fontSize: 16, marginTop: 3 }}>
                                                    Not yet signed
                                                 </span>
                                            </>
                                        )}

                                    </div>
                                    <div
                                        style={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            display: "flex",
                                        }}
                                    >
                                        <h3 style={{ fontWeight: "light" }}>Hours Logged: </h3>{" "}
                                        &nbsp;&nbsp;
                                        <span style={{ fontSize: 16, marginTop: 3 }}>
                                            {item.hoursLogged}hrs
                                        </span>
                                    </div>
                                    {getStatusValue(item.week) == 'approved' && (
                                        <>
                                        <div
                                        style={{
                                            paddingTop: 0,
                                            paddingBottom: 0,
                                            display: "flex",
                                        }}
                                    >
                                        <span style={{ fontWeight: "light" }}>THIS TIMESHEET IS APPROVED </span>{" "}
                                        &nbsp;&nbsp;
                                        <span style={{ fontSize: 16, marginTop: 3 }}>
                                            <FaCheckCircle style={{ color: "green", fontSize: "15px" }} />
                                        </span>
                                    </div>
                                        </>
                                    )}
                                    
                                </Card.Description>
                            </>
                        </Card.Content>
                    </Link>
                </Card>
            ))}
        </Card.Group>
    );
};

export default TimesheetCardsAdmin;

import React from "react";
import { Card } from "semantic-ui-react";
import { Link } from "react-router-dom";

const cardColors = ["#ede7f6", "#e8f5e9", "#e3f2fd", "#fbe9e7"];

const TimesheetCards = ({ timesheetData, id }) => {
    if (!timesheetData || timesheetData.length === 0) {
        return <div>No timesheet data available</div>;
    }

    return (
        <Card.Group stackable itemsPerRow={3}>
            {timesheetData.map((item, index) => (
                <Card
                    key={index}
                    style={{
                        backgroundColor:
                            cardColors[Math.floor(Math.random() * cardColors.length)],
                        padding: 25,
                        marginBottom: 20,
                        height: 200,
                    }}
                    raised
                >
                    <Link
                        to={`/dashboard/timesheet/view/${id}?week=${item.week}`}
                        style={{
                            textDecoration: "none",
                            textAlign: "justify",
                            color: "inherit",
                        }}
                    >
                        <Card.Content>
                            <h2 style={{ textAlign: "center", paddingTop: 50 }}>
                                Week {item.week}
                            </h2>
                        </Card.Content>
                    </Link>
                </Card>
            ))}
        </Card.Group>
    );
};

export default TimesheetCards;
